import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import appendQueryString from '../utils/query';
import { handleQuoteButtonClick, handleMailingListButtonClick } from '../utils/button';

const Brand = ({ data }) => {
  const {
    title, path, brand, SEODescription,
  } = data.brand.frontmatter;
  const { html } = data.brand;
  const quoteButtonIds = ['QuoteButtonBottom'];

  useEffect(() => {
    /**
    * When deploying on Amplify/Netlify with query parameters, the button does not
    * retain the query parameters. So we resort to a bit of a hack.
    */
    quoteButtonIds.forEach((value, _index, _array) => {
      const currentButton = document.getElementById(value);
      currentButton.href = appendQueryString(currentButton.href);
    });
  }, []);

  return (
    <Layout bodyClass="page-brand">
      <SEO
        title={title}
        description={SEODescription}
        path={path} />
      <Container className="pt-4 pt-md-4 brand">
        <Row className="content" dangerouslySetInnerHTML={{ __html: html }} />
        <Row className="pt-4">
          {data.products.edges.map((edge) => (
            <Col key={edge.node.frontmatter.path}>
              <Card>
                <Card.Img
                  src={edge.node.frontmatter.productImage.publicURL}
                  style={{ maxWidth: 400, maxHeight: 200 }} />
                <Card.Body>
                  <Card.Title>
                    <Link to={appendQueryString(edge.node.frontmatter.path)}>
                      {edge.node.frontmatter.product}
                    </Link>
                  </Card.Title>
                  <Card.Text>{edge.node.frontmatter.introduction}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className="center mb-5 text-center">
          <h3>
            Are you ready to find the right solar solution for you?
            <br />
            EcoEnergy can instantly configure a solution.
          </h3>
        </Row>
        <Row className="center mb-2">
          <Button id="QuoteButtonBottom" href="https://quote.ecoenergy.global/" variant="success" size="lg" className="center" block onClick={handleQuoteButtonClick}>
            Get a Quote in 5 Minutes
          </Button>
        </Row>
        <Row className="center mb-5">
          <Button id="MailingListButtonBottom" href="http://eepurl.com/hiAsVX" variant="warning" size="lg" className="center" block onClick={handleMailingListButtonClick}>
            Subscribe to Our Newsletter and Learn About Solar
          </Button>
        </Row>
      </Container>
    </Layout >
  );
};

export const query = graphql`
  query($id: String!, $brand: String!) {
    brand: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        brand
        title
      }
      html
    }
    products: allMarkdownRemark(filter: {frontmatter: {isVisible: {eq: true}, brand: {eq: $brand}, type: {eq: "product"}}}, sort: {fields: [frontmatter___product], order: ASC}) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            product
            introduction
            productImage {
              childImageSharp {
                fixed(height: 200) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default Brand;
